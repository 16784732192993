<template>
	<div class="page page-agenda">
		<h2 class="is-title is-inverted is-simple">{{ title }}</h2>
		<div class="page-background is-short" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="container">
			<AgendaContent v-if="loaded" name="Event" :categories="categories" :sessions="sessions" :alt-title="false" :starred="true" />
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import meta from '../utils/meta';

	import AgendaContent from '../components/AgendaContent';

	export default {
		name: 'Agenda',
		components: { AgendaContent },
		data() {
			return {
				title: 'Event programme',
				subtitle: '',
				background: '',
				categories: [],
				sessions: [],
				loaded: false,
			};
		},
		computed: {
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-event-programme'), $http.get(`/event-programme`), $http.get(`/session-categories`)]).then(
				$http.spread((page, data, categories) => {
					next(vm => {
						vm.setPage(page);
						vm.setData(data, categories);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-event-programme'), $http.get(`/event-programme`), $http.get(`/session-categories`)]).then(
				$http.spread((page, data, categories) => {
					this.setPage(page);
					this.setData(data, categories);
					next();
				})
			);
		},
		methods: {
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background;
			},
			setData(data, categories) {
				this.sessions = data.data;
				this.categories = categories.data.sort((a, b) => a.order - b.order);
				this.loaded = true;
			},
		},
	};
</script>
